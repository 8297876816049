import { lazy } from "react";


import requireAuth from "../components/shared/authentication/require-auth";

import ScrollToTop from "../components/shared/scroll-to-top/ScrollToTop";
import { connect } from "react-redux";

export const forgotPassword = lazy(()=> import("../components/phone-reset/forgot-password/forgot-password"))
export const HemmaSuccessDetails = lazy(()=> import("../components/hemma-success"))
export const BookletDetailsComponent = lazy(()=> import("../components/booklet-for-sell/booklet-details"))
export const BookletComponent = lazy(()=> import("../components/booklet-for-sell/booklet-list"))
export const Healthy = lazy(()=> import("../components/shared/healthy"))
export const TrainingResult = lazy(()=> import("../components/categories/quick-questions/training/training-result"))
export const TrainingExamDetails = lazy(()=> import("../components/categories/quick-questions/training/training-details"))
export const StartTrainingExam = lazy(()=> import("../components/categories/quick-questions/training/start-training"))
export const ExamResult = lazy(()=> import('./../components/categories/quick-questions/exams/exam-result'))
export const ExamDetails = lazy(()=> import("../components/categories/quick-questions/exams/exam-details"))
export const StartExam = lazy(()=> import("../components/categories/quick-questions/exams/start-exam"))
export const Preparing = lazy(()=> import("../components/account/certificates/preparing"))
export const Certificate = lazy(()=> import('./../components/account/certificates/certificate'))
export const CertificatesList = lazy(()=> import('./../components/account/certificates/certificates-list'))
export const InitiativesDetails = lazy(()=> import('./../components/initiative/initiatives-details'))
export const InitiativesExam = lazy(()=> import('./../components/initiative/initiatives-exam'))
export const InitiativesRole = lazy(()=> import('./../components/initiative/initiatives-role'))
export const EnterToLecture = lazy(()=> import('./../components/initiative/enter-lecture'))
export const BillingList = lazy(()=> import('./../components/account/billings/billing-list'))
export const BillingCourses = lazy(()=> import('./../components/account/billings/billing-courses'))
export const QuestionSummary = lazy(()=> import('./../components/categories/quick-questions/question-summary'))
export const QuickQuestion = lazy(()=> import('./../components/categories/quick-questions/quick-question'))
export const Competition = lazy(()=> import('./../components/categories/competitions/competition'))
export const QuickQuestions = lazy(()=> import('./../components/categories/quick-questions/quick-questions'))
export const Transaction = lazy(()=> import('./../components/cart/transaction'))
export const FAQ = lazy(()=> import('./../components/faq/faq'))
export const Search = lazy(()=> import('../components/search/search'))
export const BankAccounts = lazy(()=> import('./../components/banks/banks'))
export const Account = lazy(()=> import('./../components/account/settings/account'))
export const NotFound = lazy(()=> import('./../components/shared/not-found/not-found'))
export const Subscriptions = lazy(()=> import('./../components/account/subscriptions/subscriptions'))
export const SubscriptionDetails = lazy(()=> import('./../components/account/subscriptions/subscription-details'))
export const Cart = lazy(()=> import('./../components/cart/cart'))
export const Checkout = lazy(()=> import('./../components/cart/Checkout'))
export const VerifyId = lazy(()=> import('./../components/phone-reset/verify-id/verify'))
export const CourseDetails = lazy(()=> import('./../components/courses/details'))
export const CategoryDetails = lazy(()=> import('./../components/categories/details'))
export const PrivacyPolicy = lazy(()=> import('./../components/privacypolicy/privacypolicy'))
export const Home = lazy(()=> import("./../components/home/home"))
export const Categories = lazy(()=> import('./../components/categories/list'))
export const Verification = lazy(()=> import('./../components/verification/verification'))
export const Auth = lazy(()=> import('./../components/auth/auth'))
export const resetPassword = lazy(()=> import('./../components/phone-reset/reset-password/reset-password'))
export const BooksOrders = lazy(()=> import('../components/account/booklet-orders/booklets-orders'))
export const HomeVideo = lazy(()=> import('../components/home-video/home-video'))
