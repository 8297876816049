import { getAuthenticatedAxios, getDataFromResponse } from "./helpers";

export const CartApiEndpoints = {
  getCart: () =>
    getAuthenticatedAxios()
      .get("BookletStoreRequest/GetBookletStoreCart")
      .then(getDataFromResponse),

  addCoupon: (coupon) =>
    getAuthenticatedAxios()
      .post("BookletStoreRequest/BookletStoreAddCoupon", { coupon })
      .then(getDataFromResponse),

  removeCoupon: (couponId) =>
    getAuthenticatedAxios()
      .delete(`BookletStoreRequest/BookletStoreRemoveCoupon?id=${couponId}`)
      .then(getDataFromResponse),

  addCourse: (courseId) =>
    getAuthenticatedAxios()
      .post(`cart_v2/items/courses`, { courseId })
      .then(getDataFromResponse),

  addBooklet: (bookletId, type) =>
    getAuthenticatedAxios()
      .post(`cart_v2/items/booklets`, { bookletId, type })
      .then(getDataFromResponse),
  addBookletForSale: (bookletId, type, bookletCount) =>
    getAuthenticatedAxios()
      .post(`cart_v2/items/bookletsForSale`, { bookletId, type, bookletCount })
      .then(getDataFromResponse),
  addInstallment: (subscriptionId, amount = null) =>
    getAuthenticatedAxios()
      .post(`cart_v2/items/installments`, { subscriptionId, amount })
      .then(getDataFromResponse),

  removeCartItem: (itemId) =>
    getAuthenticatedAxios()
      .delete(`BookletStoreRequest/BookletStoreRemoveItem?id=${itemId}`)
      .then(getDataFromResponse),

  setPackageOption: (itemId, value) =>
    getAuthenticatedAxios()
      .put(`cart_v2/items/${itemId}/packageOption`, { value })
      .then(getDataFromResponse),

  setInstallment: (itemId, installment) =>
    getAuthenticatedAxios()
      .put(`cart_v2/items/${itemId}/installment`, { installment })
      .then(getDataFromResponse),

  setBookletType: (bookletId, type) =>
    getAuthenticatedAxios()
      .put(`cart_v2/items/${bookletId}/bookletType`, { type })
      .then(getDataFromResponse),

  updateCartItem: (item, data) =>
    getAuthenticatedAxios()
      .put(`cart/items/${item.id}?isBook=true`, data)
      .then(getDataFromResponse),

  uploadBankSlip: (file) => {
    let data = new FormData();
    data.append("file", file);

    return getAuthenticatedAxios()
      .post(`payments/bank_transfers/uploads`, data)
      .then(getDataFromResponse);
  },

  initiateOnlineCheckout: (data) =>
    getAuthenticatedAxios()
      .post(`BookletStoreRequest/BookletStoreSTSCardPayment`, data)
      .then(getDataFromResponse),
  initiateTapOnlineCheckout: (data) =>
    getAuthenticatedAxios()
      .post(`BookletStoreRequest/InitiateBookletStoreCardPaymentGateway`, data)
      .then(getDataFromResponse),
  GetTapPaymentDetailsCheckout: (id) =>
    getAuthenticatedAxios()
      .post(`BookletStoreRequest/GetBookletStoreTapPaymentDetails?chargeId=${id}`)
      .then(getDataFromResponse),
  getCardType: () =>
    getAuthenticatedAxios()
      .get(`cart_v2/Get_Card_type`)
      .then(getDataFromResponse),
  checkoutWithBankTransfer: (data) =>
    getAuthenticatedAxios()
      .post(`BookletStoreRequest/BookletStoreCheckoutWithBankTransfer`, data)
      .then(getDataFromResponse),

  getCities: () =>
    getAuthenticatedAxios()
      .get(`SACities/lookup/all`)
      .then(getDataFromResponse),
};
