import React, { useState } from 'react'
import { CardActivateModal } from './card-activate-Modal'

const CardActivateBtn = ({hasOverlay, fontSize, width, height, imgWidth}) => {
    const [isModalOpen, setisModalOpen] = useState(false)
    const closeModal = ()=>{
        setisModalOpen(false)
    }
    const openModal = ()=>{
        setisModalOpen(true)
    }
  return (
    <>
        <button 
            onClick={openModal}
            // className={`${hasOverlay? "btn-dark":"btn-tomato"} m-auto position-relative `}
            className="btn-yellow m-auto position-relative"
            style={{width, fontSize, height, lineHeight: height}}
        >
            <img 
            src={process.env.PUBLIC_URL + "/assets/images/main-light-play-video.png"} 
            // width={imgWidth? imgWidth : "20"}
            width={30}
            height={20}
            alt='main-light-play-video'
            />
            <span className="ml-1 small font-weight-bold">
            طريقة تفعيل بطاقة همة
            </span>
        </button>

        <CardActivateModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
        />
    </>
  )
}

export default CardActivateBtn