import React, { Component } from "react";
import Modal from "react-modal";
import Loader from "react-loaders";
import "loaders.css/src/animations/ball-clip-rotate.scss";
import "./styles.css"

export class CardActivateModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const customStyles = {
      content: {
        top: "125px",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translateX(-50%)",
        width: window.innerWidth < 767? "90%" : window.innerWidth < 992? "70%" : "60%",
        height: window.innerWidth < 576? "300px" : "435px",
        // height: "435px",
        padding: "3px !important",
        borderWidth: 0
      },
      overlay: {
        backgroundColor: "rgba(0,0,0,0.8)",
        zIndex: 20
      }
    };
    const { isModalOpen, closeModal } = this.props;

    return (
      <React.Fragment>
        <Modal
          style={customStyles}
          ariaHideApp={false}
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          closeModal={closeModal}
        >
          <div className="modal-container">
            <div className='video-container box-layout d-flex align-items-center mb-2'>
              <div className="video-responsive">
                <iframe
                // width="853"
                // height="280"
                src="https://www.youtube.com/embed/VF0Gkkp4uPk?si=nY3ceNxNDmnRpL70"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
                />
              </div>
            </div>

            <div className="w-100 d-flex align-items-center justify-content-center">
                <button
                className="btn light-btn unset-height w-25 my-3"
                onClick={closeModal}
                >
                إغلاق
                </button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
