import errors from '../../errors.json'
import swal from "@sweetalert/with-react";


const customErrorMessage = (error) => {
    // console.log("myError: ", error.response.data);
    //  Handle Custom-Arrabic-Error-Message
    var arrabicMessage

    if(error.response && error.response.data){
        let errorText = error.response?.data?.error || error.response?.data?.Error
        let messageText = error.response?.data?.message || error.response?.data?.Message

        

        if(messageText == "UserBlocked"){
            arrabicMessage = `عفوًا تم حظر حسابك لمحاولة الدخول من أكثر من جلسة!
            برجاء التواصل مع خدمة العملاء لتفعيل الحساب مرة أخرى.`
        }
        else if(messageText == "LoginFromAnotherSession"){
            arrabicMessage = `لقد تم تسجيل الدخول من جلسة اخرى برجاء تسجيل الخروج ثم إعادة تسجيل الدخول`
        }
        else{
            if(errorText && !errorText.includes("Exception") && !errorText.includes("Hemma")){
                // let message = errorText
                if(errors[errorText]){
                    console.log("errorText: ", errorText);
                    arrabicMessage = `${errors[errorText]},
                    ${errors.ContactCustomerService}`
                }
                else {
                    arrabicMessage = `${errors.GeneralError} ${errorText}`
                }
            }
            else if(messageText && !messageText.includes("Exception") && !messageText.includes("Hemma")){
                // let message = error.response.data.Message
                if(errors[messageText]){
                    arrabicMessage = `${errors[messageText]}, 
                    ${errors.ContactCustomerService}`
                }
                else {
                    arrabicMessage = `${errors.GeneralError} ${messageText}`
                }
            }
            else{
                arrabicMessage = `${errors.GeneralError} ${error}`
            }
        }

    }
    else if(error.message){
        let message = error.message
        if(errors[message]){
            arrabicMessage = `${errors[message]}, ${errors.ContactCustomerService}`
        }
        else {
            arrabicMessage = `${errors.GeneralError} ${message}`
        }
    }
    else{
        arrabicMessage = `${errors.GeneralError} ${error}`
    }
    return swal("عفوا", arrabicMessage, "error", {button: "متابعة"}).then(() => {
        if(error.response?.data?.message === "LoginFromAnotherSession" || error.response?.data?.message === "UserBlocked"){
            localStorage.removeItem("token");
            localStorage.removeItem('account');
            localStorage.removeItem('checkbox');
            const url =`/home`
            window.location.href = url;
        }
        if(error.response?.data.Error === "UserNotSubscribed"){
            const url =`/`
            window.location.href = url;
        }
    });
}

export default customErrorMessage